@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Hind:wght@400;700&display=swap');

:root {
  --text-main-color: #0070C0;
  --card-bg: #FFF9E5;
  --text-font: 'Hind', sans-serif;
  --placeholder: #74A9CE;
  --button-bg: #F9EDC8;

  /*PRIDE COLORS*/
  --rainbow-red: #FE0000;
  --rainbow-orange: #FE6600;
  --rainbow-yellow: #FFC102;
  --rainbow-green: #02AF4F;
  --rainbow-blue: #0070C0;
  --rainbow-purple: #6500CB;
}

h1, h2, h3 {
  font-family: var(--text-font);
  font-weight: 700;
}

.container {
  max-width: 910px !important;
}